export const loadScripts = async (carouselBool) => {
    // Charts
    if (document.querySelector(".charts-above-the-fold")) {
        // Highcharts Block
        if (document.querySelector(".highcharts-block-js")) {
            await import("../../../../../us/js/src/highcharts-block.js");
        }

        // Performance Charts (highcharts)
        if (document.querySelector(".performance-chart-block-js")) {
            await import("../../../../../eu/js/src/sources/performance-charts.js");
        } 
    };

    // Education scripts for carousel
    if (document.querySelector('.fund-carousel-block__main--desktop:not(.fund-carousel-block__main-au-campaign)')) {
        await import("../override/education.js");
    }; 

    // Campaign pages scripts for carousel
    if (document.querySelector('.fund-carousel-block__main--desktop.fund-carousel-block__main-au-campaign')) {
        await import("../override/campaign-page.js");
    }; 

    // Carousels
    if (document.querySelector(".carousels-above-the-fold") && carouselBool) {
        await import("./carousels.js");
    };
};

export const loadScrollDelayedScripts = async (carouselBool) => {
    // Expandable Nav
    if (document.querySelector("ve-expandablemenu") && !window.ExpandableMenu) {
        window.ExpandableMenu = true;
        await import("../override/expandable-menu/expandable-nav.js");
    };

    // Homepage Carousel
    if (document.querySelector(".promo-banner-hp")) {
        await import("../override/home-page-carousel.js");
    };

    // Charts
    if (!document.querySelector(".charts-above-the-fold")) {
        // Highcharts Block
        if (document.querySelector(".highcharts-block-js")) {
            await import("../../../../../us/js/src/highcharts-block.js");
        }

        // Performance Charts (highcharts)
        if (document.querySelector(".performance-chart-block-js")) {
            await import("../../../../../eu/js/src/sources/performance-charts.js");
        } 
    };

    // Carousels
    if (!document.querySelector(".carousels-above-the-fold") && carouselBool) {
        await import("./carousels.js");
    }
};