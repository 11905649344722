/*
 Code for the twitter share link
 */

/*const axios = require('axios');*/
const { current } = require('../../../../../js/scrollify');

$(document).ready(function () {
    if ($("body.page-class-insights").length == 0 && $("body.voices-page").lenght == 0) return
    init = function () {
        loadTwitterBlock();
        loadLinkedInUrl();
        openShareModal();
        loadFacebookUrl();
        printPage();
        copyLink();
    }
    openShareModal = function () {
        var urlParams = new URLSearchParams(window.location.search);
        var submitted = urlParams.has('openForm');
        if (submitted) {
            $('#friend-form').modal('show');
        }
    }

    $(".share-with-a-friend-form__close.close-icon").click(function() {
        window.commonFunctions.trimUrl();
    })
    
    loadTwitterBlock = function () {
        const twitterBlocks = $('#twitter, .callout-text .callout-text__icon-link');
        if (twitterBlocks) {
            twitterBlocks.each(function () {
                var createdUrl = sanitiseURL(window.location.href);
                var text = $(this).data("text");
                var author = $(this).data("author") ? "-" + $(this).data("author") : "";
                var composedUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(text) + " " + encodeURIComponent(author) + "&url=" + createdUrl;
                $(this).attr("href", composedUrl);
            })
        }
    }

    loadLinkedInUrl = function () {
        const linkedInBlocks = $('.share-widget__menu-inner #linkedin');
        if (linkedInBlocks) {
            linkedInBlocks.each(function () {
                var composedUrl = "https://www.linkedin.com/cws/share?url=" + sanitiseURL(window.location.href)
                $(this).attr("href", composedUrl);
            });
        }
    }

    loadFacebookUrl = function () {
      // Facebook Url
      $(".share-widget").each(function () {
        const url = sanitiseURL(window.location.href);
        const facebook = $(".share-widget #facebook");

        facebook.attr({
          href: `https://www.facebook.com/sharer.php?u=${url}`,
          target: "_blank",
        });
      });
    };

    printPage = function () {
        // Print page
        $('.share-widget .print').on('click', function () {
            $('.share-widget').removeClass('show');
            $('.share-widget__menu').removeClass('show');
            window.print();
        });
    }

    copyLink = function () {
        const url = sanitiseURL(window.location.href);
        $('.share-widget .copy-link').on('click', function (event) {
            event.stopPropagation();
            navigator.clipboard.writeText(url).then(() => {
                $(this).children("span").text("Link copied!");
                $(this).blur();
            }, () => {
                console.error('Failed to copy');
            });
        });

        $('.share-widget').on('hide.bs.dropdown', () => {
            $('.share-widget .copy-link span').text("Copy Link");
        });
    }

/*    getTinyUrl = function (url) {
        axios({
            method: 'get',
            url: `https://tinyurl.com/api-create.php?url=` + url,
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response.data)
                    return response.data;
                }
            })
            .catch(function (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            });
    }*/
    init();
    
});

/***
 * Easter Eggs for the NFT Campaign
 * */
function activateEasterEggs() {
    $(".easter-egg-icon").each((i, e) => {
        var $e = $(e);
        $e.append("<img src='" + $e.data("icon") + "'>")
    });

    $('.easter-egg-icon').each(function (i, e) {
        var $ele = $(e),
            target = $ele.data("target");
            popoverClass = $ele.data("class") ?? "";
            $popoverLink = $(target).length > 0 ? $(target) : $("#" + target);
            popoverID = 'popoverid' + String(Math.random()).substr(2);
        $(this).attr('id', popoverID);
        $popoverLink.attr('id', ('po' + popoverID));
        $ele.popover({
            template: `<div class="popover ${popoverClass}" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>`,
            html: true,
            trigger: 'click',
            container: 'main',
            content: function () {
                var id = $(this).attr('id');
                return $('#po' + id).html();
            },
            popperConfig: {
                placement: 'auto',
            },
        });
    });

    $(document).click(e => {
        if (!($(e.target).hasClass(".easter-egg-icon") || $(e.target).parents(".easter-egg-icon").length > 0 || $(e.target).hasClass("popover") || $(e.target).parents(".popover").length > 0)) {
            $(".easter-egg-icon").popover("hide");
        }
    })
}

window.addEventListener("DOMContentLoaded", activateEasterEggs)


window.commonFunctions = {
    hideElementOnOffset: (offset, element) => {
        var currentScrollTopVal = $(window).scrollTop();
        if (currentScrollTopVal > offset) {
            element.hide();
        } else {
            element.show();
        }
    },
    splitUrl: (currentLocation) => {
        var splitURL = currentLocation.split("#")[0];
        if (splitURL.includes("?")) {
            var newURL = splitURL + "&openForm=true";
        } else {
            var newURL = splitURL + "?openForm=true";
        }
        window.location.href = newURL;
    },
    trimUrl: () => {
        var tempLocation = window.location.href;
        if (tempLocation.includes("&openForm=true")) {
            var baseURL = tempLocation.split("&openForm")[0];
        } else if (tempLocation.includes("?openForm=true")) {
            var baseURL = tempLocation.split("?openForm")[0];
        }
        window.history.pushState("", "", baseURL);
    }

}

function sanitiseURL(url) {
  const whiteListURLPatters = ["/insights/topics/"];

  url = url ? url : window.location.href;
  const splitURL = url.split("?");

  //if the url does not have a query string
  if (splitURL.length == 1) return url;

  const safeURL = splitURL[0];
  //see if it is in the whitelist
  if (whiteListURLPatters.find((x) => safeURL.includes(x))) return url;

  return safeURL;
}